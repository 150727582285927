import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import db from "../../firebase/firebaseDatabase";
import loadinganim from "../../Assets/loading.json";
import { collection, getDocs } from "firebase/firestore/lite";
import Lottie from "react-lottie";
function Projects() {
  const [projects, setProjects] = useState([]);
  const [isloading, setLoading] = useState(true);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadinganim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    if (projects.length === 0) {
      getProjects(db);
    }
  }, [projects]);
  const getProjects = async (db) => {
    const projectCol = collection(db, "projects");
    const projectSnapshot = await getDocs(projectCol);
    const projectList = projectSnapshot.docs.map((doc) => doc.data());

    setProjects(projectList);
    setLoading(false);
  };
  return (
    <Container fluid className="project-section">
      <Particle />

      <Container>
        <h1 className="project-heading">
          Our Recent <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects we've worked on recently.
        </p>
        {!isloading ? (
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            {projects.map((project) => {
              console.log(project["desc"].toString());
              return (
                <Col md={4} className="project-card">
                  <ProjectCard
                    imgPath={project["image"].toString()}
                    isBlog={false}
                    title={project["title"]}
                    description={project["desc"].toString()}
                    link={project["link"].toString()}
                  />
                </Col>
              );
            })}
          </Row>
        ) : (
          <Container>
            <Lottie options={defaultOptions} height={200} width={200} />
          </Container>
        )}
      </Container>
    </Container>
  );
}

export default Projects;
