import React from "react";
import Card from "react-bootstrap/Card";
import { ImCheckmark } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            We work with a team of an experienced software engineers who have
            worked for a long time in the corporate field. We ensure full
            customer satisfaction by providing enterprise level products and
            best in class support to our clients.
          </p>
          <p style={{ textAlign: "justify" }}>
            We are experienced in the following fields:
          </p>
          <ul>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">
                Web Development (ASP.NET, PHP, Django, React, Flask)
              </b>
            </li>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">Android with Java, Flutter and Kotlin</b>
            </li>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">IOS with Flutter</b>
            </li>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">Unreal Engine, Unity Engine Game Development</b>
            </li>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">
                VR Game And App Development (Oculus Rift, Oculus Quest 2, VIVE,
                Valve Index)
              </b>
            </li>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">
                UI/UX Design (Adobe XD, Photoshop, Illustrator)
              </b>
            </li>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">Machine Learning with Python</b>
            </li>
          </ul>
          <p style={{ textAlign: "justify" }}>We will provide you:</p>
          <ul>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">Full Customer Satisfaction</b>
            </li>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">
                Professional Team of Developers working on your project
              </b>
            </li>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">
                1 Month free of cost support for any bug in the delivered work
              </b>
            </li>
            <li className="about-activity">
              <ImCheckmark color={"#f3b340"} />{" "}
              <b class="yellow">Excellent communication and understanding</b>
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
