import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/laptopanim.json";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import Lottie from "react-lottie";
function Home() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: homeLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Welcome to{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋
                </span>
              </h1>

              <h1 className="heading-name">
                <strong className="main-name">Axenet IT Services</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

         
              <Lottie options={defaultOptions} height={400} width={400} />
       
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
