import React, { useState } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import { Checkmark } from "react-checkmark";
import FloatingLabel from "react-bootstrap-floating-label";
function Hire() {
  const [isSubmitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [aboutproject, setAboutProject] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("https://formsubmit.co/8e87e74a1da085f97e655c8d4a73f706", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        aboutproject,
      }),
    }).then((response) => {
      setSubmitted(true);
    });
  };
  return (
    <Container fluid className="resume-section">
      {!isSubmitted ? (
        <Container>
          <h1 className="project-heading">
            Hire <strong className="purple">Us </strong>
          </h1>
          <p style={{ color: "white" }}>
            Let's get to work! Please answer a couple of short questions about
            you and your project.
          </p>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Name"
                  type="text"
                  required
                  onChange={(e) => setName(e.target.value)}
                ></FloatingLabel>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md sm xs>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Email address"
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                ></FloatingLabel>
              </Col>
              <Col md sm xs>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Phone Number"
                  type="number"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                ></FloatingLabel>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md>
                <Form.Label>About Your Project</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  placeholder="Tell us About Your Project Briefly"
                  onChange={(e) => setAboutProject(e.target.value)}
                />
              </Col>
            </Row>
            <div class="d-grid gap-2">
              <Button
                variant="primary"
                className="btn btn-primary btn-lg btn-block"
                type="submit"
              >
                Request a Quote
              </Button>
            </div>
          </Form>
          <Particle />
        </Container>
      ) : (
        <Container>
          <Particle />
          <Row className="mb-3">
            <Col md>
              <Checkmark size="xxLarge" />
            </Col>
          </Row>
          <h1 className="project-heading">
            We've been <strong className="purple">Notified </strong>
          </h1>
          <p style={{ color: "white" }}>
            We will get back to you soon regarding your query.
          </p>
        </Container>
      )}
    </Container>
  );
}

export default Hire;
