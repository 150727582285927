import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import ReactTooltip from 'react-tooltip';
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiJava,
  DiMongodb,
  DiPython,
  DiGit,
  DiAndroid,
  DiApple,
  DiPhp,
} from "react-icons/di";
import { SiPytorch, SiTensorflow, SiFirebase,SiFlutter,SiUnity,SiKotlin,SiUnrealengine } from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="unity_3d">
        <SiUnity /></a>
        <ReactTooltip id="unity_3d" place="bottom" type="light">
        <span>Unity 3D</span>
        </ReactTooltip>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="unreal">
        <SiUnrealengine /></a>
        <ReactTooltip id="unreal" place="bottom" type="light">
        <span>Unreal Engine</span>
        </ReactTooltip>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="flutter">
        <SiFlutter /></a>
        <ReactTooltip id="flutter" place="bottom" type="light">
        <span>Flutter</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="javascript">
        <DiJavascript1 /></a>
        <ReactTooltip id="javascript" place="bottom" type="light">
        <span>Javascript</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="node_js">
        <DiNodejs /></a>
        <ReactTooltip id="node_js" place="bottom" type="light">
        <span>NodeJS</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="react_js">
        <DiReact /></a>
        <ReactTooltip id="react_js" place="bottom" type="light">
        <span>ReactJS</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="php">
        <DiPhp /></a>
        <ReactTooltip id="php" place="bottom" type="light">
        <span>PHP</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <a data-tip data-for="occulus">
      <svg
  width="64"
  height="64"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16 10H8C6.89543 10 6 10.8954 6 12C6 13.1046 6.89543 14 8 14H16C17.1046 14 18 13.1046 18 12C18 10.8954 17.1046 10 16 10ZM8 6C4.68629 6 2 8.68629 2 12C2 15.3137 4.68629 18 8 18H16C19.3137 18 22 15.3137 22 12C22 8.68629 19.3137 6 16 6H8Z"
    fill="currentColor"
  />
</svg></a>
        <ReactTooltip id="occulus" place="bottom" type="light">
        <span>Occulus (VR)</span>
        </ReactTooltip>
     
      </Col>
      <Col xs={4} md={2} className="tech-icons" >
        <a data-tip data-for="mongodb">
        <DiMongodb/></a>
        <ReactTooltip id="mongodb" place="bottom" type="light">
        <span>MongoDB</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="python">
        <DiPython /></a>
        <ReactTooltip id="python" place="bottom" type="light">
        <span>Python</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="java">
        <DiJava /></a>
        <ReactTooltip id="java" place="bottom" type="light">
        <span>Java</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="kotlin">
        <SiKotlin /></a>
        <ReactTooltip id="kotlin" place="bottom" type="light">
        <span>Kotlin</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="android">
        <DiAndroid /></a>
        <ReactTooltip id="android" place="bottom" type="light">
        <span>Android Application</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="iOS">
        <DiApple /></a>
        <ReactTooltip id="iOS" place="bottom" type="light">
        <span>iOS Application</span>
        </ReactTooltip>
        
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a data-tip data-for="firebase">
        <SiFirebase /></a>
        <ReactTooltip id="firebase" place="bottom" type="light">
        <span>Firebase</span>
        </ReactTooltip>
        
      </Col>
    </Row>
  );
}

export default Techstack;
