import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import { ImCompass } from "react-icons/im";
import logo from "../../Assets/axenet.png";
import ReactRoundedImage from "react-rounded-image";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="yellow"> ABOUT </span> US
            </h1>
            <p className="home-about-body">
              We work with a team of an experienced software engineers who have
              worked for a long time in the corporate field. We ensure full
              customer satisfaction by providing enterprise level products and
              best in class support to our clients.
              <br />
              <br />
              <h3 style={{ fontSize: "1.6em" }}>
                <b className="yellow">
                  <ImCompass /> MOBILE APP DEVELOPMENT{" "}
                </b>
              </h3>
              We design and develop custom mobile application for Android and
              IOS platforms. Our mobile app developers have expertise to create
              mobile applications using Flutter, React, Native Android and IOS
              technologies.
              <br />
              <br />
              <h3 style={{ fontSize: "1.6em" }}>
                <b className="yellow">
                  <ImCompass /> GAME DEVELOPMENT{" "}
                </b>
              </h3>
              Our game developers using advance technology / tools and develop
              visually attractive UI and responsive games that keeps the gamers
              engaged. We help our clients to turn their creative ideas into
              games by providing cost-effective solutions with quality. Our
              experienced programmers create theme, graphics, and build
              interactive games with innovative ideas. By using agile
              development cycles we remove all hurdles that they may encounter
              in development process and support you at every stage from game
              design to final publish in leading app stores.
              <br />
              <br />
              <h3 style={{ fontSize: "1.6em" }}>
                <b className="yellow">
                  <ImCompass /> PYTHON DEVELOPMENT{" "}
                </b>
              </h3>
              Python is one of the unique programming languages that is being
              used for Machine Learning. This language can be used for a wide
              scope of utilizations like scripting, developing websites,
              mobile-based and GUI applications. We are a top-notch Python
              Development & our certified expert provides smooth user experience
              by keeping in mind the business requirements.
              <br />
              <br />
              <h3 style={{ fontSize: "1.6em" }}>
                <b className="yellow">
                  <ImCompass /> WEB DEVELOPMENT{" "}
                </b>
              </h3>
              We have rich and sharp proficiency and extensive experience in
              creating responsive and professional websites for all types of
              businesses. To stay with the latest web technology trends and
              concepts, we keep ourselves up to date with these trends and
              emerging technology concepts. Our web development process is
              unique, well-researched and properly organized to match with every
              client’s needs and business goals.
              <br />
              <br />
            </p>
          </Col>

          <Col md={4} className="myAvtar">
            <center>
              <ReactRoundedImage
                image={logo}
                imageWidth="300"
                imageHeight="300"
                roundedSize="13"
              />
            </center>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <p>
              Feel free to <span className="yellow">connect </span>with us
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://twitter.com/AxenetIT"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.freelancer.com/u/PrashantR6"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 60 60"
                  >
                    <path
                      d="M37.6 8.203l4.36 6.113L64 8.203M14.676 55.797l11.93-11.663-7.18-7.705M35.783 8.203l-6.376 5.75 10.724.4m-29.134-6.15l2.3 4.693 12.62.786M17.753 32.54l9.324-17.393L0 13.683m18.838 19.96l8.807 9.46 9.715-9.523 3.015-17.737-11.84-.604"
                      fill="#700c86"
                    />
                  </svg>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/axenet-it-services/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/axenet.it.services/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
